<template>
    <b-modal
        id="statement-of-awareness"
        hide-header
        hide-footer
        centered
        size="lg"
        @show="onShow"
        @hide="closeModal"
    >
        <div>
            <div class="title">Selecione os itens que deseja incluir</div>
            <div class="items-wrapper">
                <div v-for="(appointmentItem, index) in form.appointment_items" :key="index">
                    <Check
                        v-if="appointmentItem.item_type === 'App\\Models\\TissGuide\\TissTableProcedure'" 
                        v-model="selected[getProcedureName(appointmentItem)]" 
                        :label="getProcedureName(appointmentItem)"  
                    />                
                    <Check 
                        v-else 
                        v-model="selected[appointmentItem.item?.name]" 
                        :label="appointmentItem.item?.name"  
                    />
                </div>
            </div>

            <div class="actions">
                <b-button 
                    variant="outline-secondary" 
                    @click="closeModal"
                >
                    Cancelar
                </b-button>
                <b-button 
                    :disabled="printing || !hasSelected"
                    variant="primary"
                    @click="print"
                >
                    Gerar declaração
                </b-button>
            </div>
        </div>
    </b-modal>
</template>
<script>
import { saveAs } from 'file-saver'

export default {
    name: 'StatementOfAwarenessModal',
    props: {
        form: Object,
    },
    components: {
        Check: () => import('@/components/General/Check')
    },
    computed: {
        hasSelected() {
            return Object.keys(this.selected).some(key => this.selected[key])
        },
    },
    data() {
        return {
            printing: false,
            selected: {},
        }
    },
    methods: {
        onShow() {
            this.selected = {}
        },
        closeModal() {
            this.$bvModal.hide('statement-of-awareness')
        },
        getProcedureName(appointmentItem) {
            return appointmentItem.item.procedure?.name || appointmentItem.item.item.procedure?.name
        },
        async print() {
            this.printing = true
            try {
                const procedures = Object.keys(this.selected).filter(key => this.selected[key])
                const { data } = await this.api.printStatementOfAwareness(this.form.patient_id, procedures)
                saveAs(new Blob([data], { type: 'application/pdf;charset=utf-8' }), `Declaração-de-ciência-procedimento.pdf`)
                this.closeModal()
            } catch (error) {
                this.$toast.error(error.message)
            } finally {
                this.printing = false
            }
        },
    }
    
}
</script>
<style lang="scss">
#statement-of-awareness {
    .modal-content {
        padding: 1rem;
    }
    .title {
        font-weight: 500;
        font-size: 18px;
        margin-bottom: 1rem;
    }
    .items-wrapper {
        border: 1px dashed var(--neutral-200);
        border-radius: 8px;
        padding: 0.75rem;
    }
    .actions {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: 10px;
        margin-top: 1rem;
    }
}
</style>